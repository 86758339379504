import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndClose,
  answerMultipleQuestionsSaveRankingsAndSubmit,
  answerMultipleQuestionsAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { getLikertBoundsInformation, LikertBounds } from '^/productVersions';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import PerspectivesLikertQuestion from './PerspectivesLikertQuestion';
import { productVersionQuestionProgress } from './QuestionProgress';
import { closeTopModal, openAnotherModal } from '^/actions/modals';
import ScrollTopOnPageChange from './ScrollTopOnPageChange';
import { QUESTION_COLLECTION_TYPES } from '^/components/productVersions/choices';
import LikertQuestion from './LikertQuestion';

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  raterFor: Map<string, any>;
  questionCollectionIdx: number;
  questionsWithAnswers: List<List<Map<string, any> | undefined>>;
}

interface DispatchProps {
  setProductVersionPage: typeof setProductVersionPage;
  storeProductVersionAnswer: typeof storeProductVersionAnswer;
  push: typeof push;
  answerMultipleQuestionsAndAdvance: typeof answerMultipleQuestionsAndAdvance;
  answerMultipleQuestionsSaveRankingsAndSubmit: typeof answerMultipleQuestionsSaveRankingsAndSubmit;
  answerMultipleQuestionsAndClose: typeof answerMultipleQuestionsAndClose;
  answerMultipleQuestionsAndSubmit: typeof answerMultipleQuestionsAndSubmit;
  openAnotherModal: typeof openAnotherModal;
  closeTopModal: typeof closeTopModal;
}

interface StateProps {
  progress: Map<string, any>;
  isPending: boolean;
  response: Map<string, any>;
  uiLanguage: string;
}

type Props = DispatchProps & StateProps & OwnProps;

const QUESTIONS_PER_PAGE = 10;

export class PerspectivesLikertProductVersion extends PureComponent<Props> {
  public componentDidMount() {
    const currentQuestionIndex = this.props.questionsWithAnswers.findIndex(
      questionAndAnswer => !questionAndAnswer.get(1)
    );
    if (currentQuestionIndex === -1) {
      // We've already answered this productVersion, so jump to the first page
      this.props.setProductVersionPage(0);
    } else {
      this.props.setProductVersionPage(
        Math.floor(currentQuestionIndex / QUESTIONS_PER_PAGE)
      );
    }
  }

  public render() {
    const {
      productVersion,
      questionCollectionIdx,
      raterFor,
      isPending,
      progress,
      activity,
    } = this.props;
    const currentQuestions = this.currentQuestions();

    const currentPage = progress.get('currentPage', 0);
    const pageCount = this.pageCount();
    const bounds = getLikertBoundsInformation(
      productVersion,
      questionCollectionIdx
    );

    const questionCollection = productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx || 0,
    ]);

    const isCompetenciesLikert =
      questionCollection.get('question_type') ===
      QUESTION_COLLECTION_TYPES.CHOICES.PERSPECTIVES_COMPETENCIES_LIKERT;

    const submitAction = isCompetenciesLikert
      ? this.props.answerMultipleQuestionsSaveRankingsAndSubmit
      : this.props.answerMultipleQuestionsAndSubmit;

    const actionCreator = this.isViewingLastPage()
      ? submitAction
      : this.props.answerMultipleQuestionsAndAdvance;

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          hideSectionName={activity.get('is_staged')}
          noLogo
        />

        <AssessmentBody
          className="mb-none"
          header={productVersionQuestionProgress(
            currentPage,
            QUESTIONS_PER_PAGE,
            this.props.questionsWithAnswers.count()
          )}
          questionIndex={currentPage}
        >
          <ScrollTopOnPageChange page={currentPage} />
          {isCompetenciesLikert ? (
            <Well>
              <p>
                {i18next.t<string>(
                  'Rate yourself against the following competencies'
                )}
              </p>
              <Trans i18nKey="Not competent">
                {''}
                <strong>1 – Not competent</strong> (I am unskilled),
              </Trans>
              <Trans i18nKey="Competent">
                {''}
                <strong>5 – Competent</strong> (I am skilled),
              </Trans>
              <Trans i18nKey="Exceptional">
                {''}
                <strong>10 – Exceptional</strong> (I am expert).
              </Trans>
            </Well>
          ) : (
            <Well>
              <Trans i18nKey="Rate from very unlike me to very like me">
                These are statements that relate to your everyday life. Please
                rate each statement from <strong>Very unlike me</strong> to{' '}
                <strong>Very much like me</strong>.
              </Trans>
            </Well>
          )}
          {currentQuestions
            .map((questionAndAnswer, index) => {
              const question = questionAndAnswer.get(0)!;
              const answer = this.getSelectedAnswer(questionAndAnswer, bounds);
              return isCompetenciesLikert ? (
                <PerspectivesLikertQuestion
                  questionText={question.get('text')}
                  factor={question.get('factor_text')}
                  answer={answer}
                  key={question.get('id')}
                  onAnswer={this.onAnswer.bind(this, question)}
                  bounds={bounds}
                  isPending={isPending}
                  size="medium"
                  questionNumber={index}
                />
              ) : (
                <LikertQuestion
                  questionText={question.get('text')}
                  answer={answer}
                  key={question.get('id')}
                  onAnswer={this.onAnswer.bind(this, question)}
                  bounds={bounds}
                  isPending={isPending}
                  size="medium"
                />
              );
            })
            .toArray()}
        </AssessmentBody>
        <AssessmentFooter
          isNotComplete={!this.isPageComplete()}
          isSaving={isPending}
          showContinue={false}
          onContinueLater={this.confirmAndClose}
          onContinue={this.checkTotals.bind(this, actionCreator)}
          isEnd={this.isViewingLastPage()}
        />
      </AssessmentContainer>
    );
  }

  private getSelectedAnswer(
    questionAndAnswer: List<Map<string, any> | undefined>,
    bounds: LikertBounds
  ) {
    const question = questionAndAnswer.get(0)!;
    const answer = this.props.progress.getIn(
      ['unsavedResponses', question.get('id')],
      null
    );

    // We may need to reverse the answer if it's a reversed question
    if (answer === null) {
      const existingAnswer = questionAndAnswer.getIn([1, 'score'], null);
      if (existingAnswer !== null) {
        if (question.get('reversed')) {
          return bounds.likert_maximum - existingAnswer + 1;
        }
        return existingAnswer;
      }
    }
    return answer;
  }

  private pageCount() {
    return Math.ceil(
      this.props.questionsWithAnswers.count() / QUESTIONS_PER_PAGE
    );
  }

  private isViewingLastPage() {
    return this.props.progress.get('currentPage', 0) === this.pageCount() - 1;
  }

  private onAnswer(question: Map<string, any>, value: string | number) {
    this.props.storeProductVersionAnswer(question.get('id'), value);
  }

  private currentQuestions() {
    return this.props.questionsWithAnswers.slice(
      this.props.progress.get('currentPage', 0) * QUESTIONS_PER_PAGE,
      (this.props.progress.get('currentPage', 0) + 1) * QUESTIONS_PER_PAGE
    );
  }
  private checkTotals = (
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsSaveRankingsAndSubmit
  ) => {
    const answers = this.props.progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number) => score)
      .toList()
      .toJS();

    const scoreUnderBound =
      answers.length > 0
        ? answers.filter((item: number) => item < 8).length
        : 1;
    if (scoreUnderBound > 0) {
      if (!this.isViewingLastPage()) {
        this.onNext(actionCreator);
      } else {
        this.onLast(actionCreator);
      }
    } else {
      this.props.openAnotherModal({
        title: i18next.t<string>('Confirm your answers'),
        body: i18next.t<string>(
          'All of your ratings are between 8 and 10, are you sure you want to process'
        ),
        footer: (
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Review')}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.closeAndNext(actionCreator)}
            >
              {i18next.t<string>('Proceed')}
            </button>
          </div>
        ),
      });
    }
  };

  private closeAndNext(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsSaveRankingsAndSubmit
  ) {
    this.onNext(actionCreator);
    this.props.closeTopModal();
  }
  private onLast(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsSaveRankingsAndSubmit
  ) {
    const nextPage = this.props.progress.get('currentPage', 0) + 1;
    const answers = this.props.progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number, question: Map<string, any>) => {
        return Map({
          question,
          score,
        });
      })
      .toList();
    const id = this.props.activity.get('id');

    actionCreator(
      this.props.activity,
      this.props.productVersion,
      this.props.raterFor,
      answers,
      nextPage,
      this.props.questionCollectionIdx,
      this.props.uiLanguage,
      id
    );
  }

  private onNext(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsSaveRankingsAndSubmit
  ) {
    const nextPage = this.props.progress.get('currentPage', 0) + 1;
    const id = this.props.activity.get('id');
    const answers = this.props.progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number, question: Map<string, any>) => {
        return Map({
          question,
          score,
        });
      })
      .toList();

    actionCreator(
      this.props.activity,
      this.props.productVersion,
      this.props.raterFor,
      answers,
      nextPage,
      this.props.questionCollectionIdx,
      this.props.uiLanguage,
      id
    );
  }

  private confirmAndClose = () => {
    if (
      window.confirm(
        i18next.t<string>(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      this.onNext(this.props.answerMultipleQuestionsAndClose);
    }
  };

  private isPageComplete() {
    return this.currentQuestions().every(questionAndAnswer => {
      const answer = questionAndAnswer.get(1);
      const question = questionAndAnswer.get(0)!;
      return (
        (answer ? answer.get('score', null) !== null : false) ||
        this.props.progress.getIn(['unsavedResponses', question.get('id')])
      );
    });
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('answerQuestion'),
      state.responses.get('updateActivityProductVersionSession'),
      state.responses.get('savePerspectivesLikert'),
      state.responses.get('loadItem'),
    ]),
    response: state.responses.get('answerQuestion'),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  push,
  openAnotherModal,
  closeTopModal,
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsSaveRankingsAndSubmit,
  answerMultipleQuestionsAndClose,
  answerMultipleQuestionsAndSubmit,
})(PerspectivesLikertProductVersion);

import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import PerspectivesCompetenciesRankedQuestionGroup from './PerspectivesCompetenciesRankedQuestionGroup';
import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  saveRankingAnswersAndShowNext,
  saveRankingAnswersAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { anyPending } from '^/responseStates';
import { generateQuestionOrder } from '^/questionGroups';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import AssessmentBody from './AssessmentBody';
import AssessmentFooter from './AssessmentFooter';

export class RankedProductVersion extends PureComponent {
  componentDidMount() {
    const currentQuestionIndex = 0;
    this.props.setProductVersionPage(currentQuestionIndex);
  }

  isViewingLastQuestion() {
    return (
      this.props.progress.get('currentPage') >=
      this.props.questionCompetenciesGroupsWithAnswers.count() - 1
    );
  }

  onNext(action) {
    const currentQuestionGroup = this.props.questionCompetenciesGroupsWithAnswers.get(
      this.props.progress.get('currentPage', 0)
    );
    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );
    action(
      this.props.activity,
      this.props.productVersion,
      this.props.uiLanguage,
      questionId,
      unsyncedAnswers ||
        generateQuestionOrder(currentQuestionGroup.get('questions')),
      this.props.questionCollectionIdx
    );
  }

  confirmAndClose() {
    if (
      window.confirm(
        i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      return; //do nothing, client wants the button to still be here even though it's broken
    }
  }

  render() {
    const {
      isPending,
      productVersion,
      questionCollectionIdx,
      raterFor,
    } = this.props;

    const currentQuestionIndex = this.props.progress.get('currentPage', 0);
    const currentQuestionGroup = this.props.questionCompetenciesGroupsWithAnswers.get(
      currentQuestionIndex
    );
    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );
    const disableSave = !unsyncedAnswers || !unsyncedAnswers.every(id => id);

    const currentPage = this.props.progress.get('currentPage', 0);
    const pageCount = this.props.questionCompetenciesGroupsWithAnswers.count();

    const action = this.isViewingLastQuestion()
      ? this.props.saveRankingAnswersAndSubmit
      : this.props.saveRankingAnswersAndShowNext;
    const count = this.props.questionCompetenciesGroupsWithAnswers
      .get(currentQuestionIndex)
      .get('questions')
      .count()
      .toString();

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          noLogo
        />

        <AssessmentBody
          header={i18next.t('Question {{current}} of {{total}}', {
            current: currentPage + 1,
            total: this.props.questionCompetenciesGroupsWithAnswers.count(),
          })}
          questionIndex={currentQuestionIndex}
        >
          <Well>
            <div>
              <Trans i18nKey="Please rank order perspectives">
                Thinking of your own personal values, needs and preferences for
                your ideal job role and workplace environment, rank order each
                of the {{ count }} statements from 'MOST important to me at work
                (1)' and 'LEAST important to me at work ({{ count }})'
              </Trans>
            </div>
          </Well>

          <PerspectivesCompetenciesRankedQuestionGroup
            questionGroup={currentQuestionGroup}
            unsyncedAnswers={unsyncedAnswers}
            reorderAnswers={this.props.storeProductVersionAnswer.bind(
              null,
              questionId
            )}
            isPending={isPending}
          />
        </AssessmentBody>

        <AssessmentFooter
          isSaving={isPending}
          showContinue={false}
          onContinueLater={() => this.confirmAndClose()}
          onContinue={this.onNext.bind(this, action)}
          isEnd={this.isViewingLastQuestion()}
          isSaveButtonDisabled={disableSave}
        />
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('saveRankingAnswers'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  saveRankingAnswersAndShowNext,
  saveRankingAnswersAndSubmit,
  push,
})(RankedProductVersion);

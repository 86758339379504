/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import { toTitleCase } from '^/utils';
import PureComponent from '^/components/PureComponent';
import ContentBox from '^/components/ContentBox';
import SelectOrganisation from '^/components/SelectOrganisation';
import DataFilterControl from '^/components/datafilters/DataFilterControl';
import {
  clearSimpleComponentState,
  setSimpleComponentState,
  getToken,
  setExportProduct,
  setExportTeam,
} from '^/actions/actions';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { NUMERICAL, RESULTS } from '^/middleware/reports';
import {
  can,
  administerOrganisations,
  administerOwnOrganisation,
  seeExportRawScores,
} from '^/capabilities';
import {
  setFilterId,
  clearFilterType,
  preselectFilterItem,
  selectFilterItem,
  FILTER_TYPE_ORGANISATION,
  FILTER_TYPE_ACTIVITY,
  FILTER_TYPE_USER,
  FILTER_TYPE_TEAM,
  FILTER_TYPE_PRODUCT,
} from '^/actions/datafilters';
import FormItem from '../forms/FormItem';
import RadioButton from '^/components/RadioButton';
import MenuButton, { MenuButtonOption } from '^/components/MenuButton';

const FILTERID = 'EXPORT_RESULTS';
const BY_ACTIVITY = 'activity';
const BY_PRODUCT = 'product';
const BY_TEAM = 'team';
const BY_USER = 'user';

export class ExportResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeamDetail:
        props.selectedTeam &&
        props.teams &&
        props.teams
          .toJS()
          .find(team => team.id === props.selectedTeam.get('value')),
    };
  }

  componentDidMount() {
    this.props.getToken();
    this.props.setFilterId(FILTERID);

    if (can(this.props.user, administerOwnOrganisation())) {
      const userOrganisation = this.props.user.get('organisation');
      this.props.preselectFilterItem(
        FILTERID,
        FILTER_TYPE_ORGANISATION,
        userOrganisation.get('id')
      );
    } else {
      this.props.clearFilterType(FILTERID, FILTER_TYPE_ORGANISATION);
    }
  }
  componentWillUnmount() {
    this.props.clearSimpleComponentState([FILTERID, 'objectType']);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.selectedTeam && prevProps.selectedTeam.get('value')) !==
      (this.props.selectedTeam && this.props.selectedTeam.get('value'))
    ) {
      if (this.props.selectedTeam) {
        this.setState({
          selectedTeamDetail: this.props.teams
            .toJS()
            .find(team => team.id === this.props.selectedTeam.get('value')),
        });
      } else {
        this.setState({ selectedTeamDetail: undefined });
      }
    }
  }

  onChangeObjectType(value) {
    this.props.setSimpleComponentState([FILTERID, 'objectType'], value);
  }

  valueIfNotAll(selectedObject) {
    const value = selectedObject.get('value', null);
    return value !== 'ALL' ? value : null;
  }

  onOpenExport(numerical) {
    const {
      user,
      shortlivedToken,
      selectedUser,
      selectedTeam,
      selectedActivity,
      selectedOrganisation,
      selectedProduct,
    } = this.props;

    if (numerical) {
      const { selectedTeamDetail } = this.state;
      this.props.setExportProduct(
        Map({ id: selectedTeamDetail && selectedTeamDetail.product.id })
      );
      this.props.setExportTeam(
        Map({ id: selectedTeam && selectedTeam.get('value') })
      );
    }

    this.props.getToken();
    this.props.closeModalAndTryViewExport(
      shortlivedToken,
      numerical ? NUMERICAL : RESULTS,
      {
        user: this.valueIfNotAll(selectedUser),
        activity: this.valueIfNotAll(selectedActivity),
        team: this.valueIfNotAll(selectedTeam),
        [selectedProduct.get('key')]: this.valueIfNotAll(selectedProduct),
        organisation:
          user.getIn(['organisation', 'id']) ||
          this.valueIfNotAll(selectedOrganisation),
      }
    );
  }

  renderExportButton(offerNumerical) {
    const { selectedProduct } = this.props;

    if (!selectedProduct) {
      return;
    }

    return (
      <div className="pull-right">
        {offerNumerical ? (
          <MenuButton primary position="right" label={i18next.t('Export CSV')}>
            <MenuButtonOption
              onSelect={() => this.onOpenExport(false)}
              name={i18next.t('Export categorical')}
            />
            <MenuButtonOption
              onSelect={() => this.onOpenExport(true)}
              name={i18next.t('Export numerical')}
            />
          </MenuButton>
        ) : (
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => this.onOpenExport()}
          >
            {i18next.t('Export CSV')}
          </button>
        )}
      </div>
    );
  }

  renderActivityFilter() {
    return (
      <div className="row mb-none">
        <div className="col-xs-12 col-md-6">
          <div className="form-group-block">
            <label>{i18next.t('Activity')}</label>
            <div className="form-item">
              <DataFilterControl
                placeholder={i18next.t('Search for activity name')}
                filterId={FILTERID}
                prefilter
                filterType={FILTER_TYPE_ACTIVITY}
                extraFilters={Map({ for_data_download: true })}
                responseName="loadActivityFilters"
              />
            </div>
          </div>
        </div>
        {!this.props.selectedActivity.isEmpty() && (
          <div className="col-xs-12 col-md-6">
            <div className="form-group-block">
              <label>{i18next.t('Product')}</label>
              <div className="form-item">
                <DataFilterControl
                  placeholder={i18next.t('Search for product name')}
                  filterId={FILTERID}
                  prefilter
                  filterType={FILTER_TYPE_PRODUCT}
                  key={`activity_product_${this.props.selectedActivity.get(
                    'value'
                  )}`}
                  responseName="loadProductFilters"
                />
              </div>
            </div>
            {this.renderExportButton()}
          </div>
        )}
      </div>
    );
  }

  renderUserFilter() {
    return (
      <div className="row mb-none">
        <div className="col-xs-12 col-md-6">
          <div className="form-group-block">
            <label>{i18next.t('User')}</label>
            <div className="form-item">
              <DataFilterControl
                placeholder={i18next.t('Search for user name')}
                filterId={FILTERID}
                prefilter
                includeAll
                filterType={FILTER_TYPE_USER}
                resultValueLabel="full_name"
                extraFilters={Map({
                  has_completed: true,
                })}
                responseName="loadUserFilters"
              />
            </div>
          </div>
        </div>
        {!this.props.selectedUser.isEmpty() && (
          <div className="col-xs-12 col-md-6">
            <div className="form-group-block">
              <label>{i18next.t('Product')}</label>
              <div className="form-item">
                <DataFilterControl
                  placeholder={i18next.t('Search for product name')}
                  filterId={FILTERID}
                  prefilter
                  key={`user_product_${this.props.selectedUser.get('value')}`}
                  filterType={FILTER_TYPE_PRODUCT}
                  responseName="loadProductFilters"
                />
              </div>
            </div>
            {this.renderExportButton()}
          </div>
        )}
      </div>
    );
  }

  renderTeamFilter() {
    const { selectedTeamDetail } = this.state;
    return (
      <div>
        <div className="row mb-none">
          <div className="col-xs-12 col-md-6">
            <div className="form-group-block">
              <label>{i18next.t('Team')}</label>
              <div className="form-item">
                <DataFilterControl
                  placeholder={i18next.t('Search for team name')}
                  filterId={FILTERID}
                  prefilter
                  filterType={FILTER_TYPE_TEAM}
                  responseName="loadTeamFilters"
                  changeCallback={team =>
                    this.props.selectFilterItem(
                      FILTERID,
                      FILTER_TYPE_PRODUCT,
                      Map({ value: 'ALL' }),
                      true
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-none">
          {!this.props.selectedTeam.isEmpty() && (
            <div className="col-xs-12 col-md-6 pull-right">
              {this.renderExportButton(
                can(this.props.user, seeExportRawScores()) &&
                  selectedTeamDetail &&
                  selectedTeamDetail.product.has_numerical_download
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderProductFilter() {
    return (
      <div>
        <div className="row mb-none">
          <div className="col-xs-12 col-md-6">
            <div className="form-group-block">
              <label>{i18next.t('Product')}</label>
              <div className="form-item">
                <DataFilterControl
                  placeholder={i18next.t('Search for product name')}
                  filterId={FILTERID}
                  prefilter
                  filterType={FILTER_TYPE_PRODUCT}
                  responseName="loadProductFilters"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-none">
          <div className="col-xs-12 col-md-12">{this.renderExportButton()}</div>
        </div>
      </div>
    );
  }

  render() {
    const {
      user,
      selectedOrganisation,
      selectedObjectType,
      selectedOrgHasTeams,
    } = this.props;
    return (
      <ContentBox>
        <h3>{i18next.t('Respondent results')}</h3>
        {can(user, administerOrganisations()) && (
          <div className="row mb-none">
            <div className="col-xs-12 col-md-6">
              <div className="form-group-block">
                <div className="form-item">
                  <SelectOrganisation
                    onChange={value =>
                      this.props.selectFilterItem(
                        FILTERID,
                        FILTER_TYPE_ORGANISATION,
                        Map({ value, isAll: value === 'ALL' }),
                        !value
                      )
                    }
                    showAll
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!selectedOrganisation.isEmpty() && (
          <FormItem
            className="form-group-block"
            label={i18next.t('Choose the type of data to filter by')}
          >
            {[BY_ACTIVITY, BY_PRODUCT]
              .concat(selectedOrgHasTeams ? [BY_TEAM] : [])
              .concat([BY_USER])
              .map(key => (
                <div className="inline margin-right" key={`div-${key}`}>
                  <RadioButton
                    id={`pp-export-org-object-type-${key}`}
                    checked={selectedObjectType === key}
                    onChange={this.onChangeObjectType.bind(this, key)}
                    label={toTitleCase(key)}
                  />
                </div>
              ))}
          </FormItem>
        )}

        {selectedObjectType === BY_ACTIVITY && this.renderActivityFilter()}
        {selectedObjectType === BY_USER && this.renderUserFilter()}
        {selectedObjectType === BY_PRODUCT && this.renderProductFilter()}
        {selectedObjectType === BY_TEAM && this.renderTeamFilter()}
      </ContentBox>
    );
  }
}

ExportResults.propTypes = {
  setFilterId: PropTypes.func.isRequired,
  clearFilterType: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.string,
  selectedOrganisation: ImmutablePropTypes.map.isRequired,
  selectedUser: ImmutablePropTypes.map.isRequired,
  selectedActivity: ImmutablePropTypes.map.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  getToken: PropTypes.func.isRequired,
  closeModalAndTryViewExport: PropTypes.func.isRequired,
  shortlivedToken: ImmutablePropTypes.map,
};

export function mapStateToProps(state) {
  const selectedOrganisation = state.datafilters.getIn(
    [FILTERID, FILTER_TYPE_ORGANISATION, 'selectedResult'],
    Map()
  );

  return {
    selectedOrganisation,
    selectedUser: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_USER, 'selectedResult'],
      Map()
    ),
    selectedActivity: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_ACTIVITY, 'selectedResult'],
      Map()
    ),
    selectedTeam: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_TEAM, 'selectedResult'],
      Map()
    ),
    teams: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_TEAM, 'results'],
      List()
    ),
    selectedProduct: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_PRODUCT, 'selectedResult'],
      Map()
    ),
    selectedObjectType: state.ui.getIn([
      'simpleComponentState',
      FILTERID,
      'objectType',
    ]),
    user: state.userProfile,
    shortlivedToken: state.shortlivedToken,
    selectedOrgHasTeams: (
      state.userProfile.get('organisation') ||
      state.collections
        .getIn(['organisationOptions', 'items'], List())
        .find(org => org.get('id') === selectedOrganisation.get('value')) ||
      Map()
    ).get('has_teams'),
  };
}

export default connect(mapStateToProps, {
  setFilterId,
  selectFilterItem,
  setSimpleComponentState,
  clearSimpleComponentState,
  clearFilterType,
  preselectFilterItem,
  getToken,
  closeModalAndTryViewExport,
  setExportProduct,
  setExportTeam,
})(ExportResults);
